import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import Echo from "laravel-echo";
import store from './store';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://' + process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use((config) => {
  config.headers['X-Socket-ID'] = window.Echo.socketId()
  config.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`
  return config
})

window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authEndpoint: 'https://' + process.env.VUE_APP_API_URL + '/api/player-api/broadcasting/auth',
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios.post('api/player-api/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        })
          .then(response => {
            callback(false, response.data);
          })
          .catch(error => {
            callback(true, error);
          });
      }
    };
  },
});


Sentry.init({
  Vue,
  dsn: "https://fe073107ca574528bb51f5b61b93cdaf@o1080813.ingest.sentry.io/6087223",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "player.reedioo.dev", "player.reedioo.com", 'player.reedioo.test', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
});

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
