<template>
  <div class="" v-if="$store.getters['auth/isAuthenticated']">
    <HtmlAudioPlayers :player="player" :sessionId="device.id"></HtmlAudioPlayers>
    <div class="">
      <div class="">
        {{ player.playable.name }}
      </div>
      <div class="">
        {{ player.track.name }}
      </div>
    </div>
  </div>
</template>

<script>
import HtmlAudioPlayers from "@/components/HtmlAudioPlayers";
export default {
  name: 'Player',
  components: {HtmlAudioPlayers},
  data() {
    return {

    }
  },

  async mounted() {
    await this.$store.dispatch('auth/auth');
    await this.$store.dispatch('player/initWebsocketListeners');

    console.log(this.$store.state.auth.device)
  },

  computed: {
    player() {
      return this.$store.state.player.player;
    },

    device() {
      return this.$store.state.auth.device;
    }
  }
}
</script>

<style scoped>
</style>
