import axios from "axios";
import store from "../index";

export default {
    namespaced: true,
    state: {
        devices: [],
        players: [],
        player: {
            is_repeat: false,
            is_random: false,
            is_playing: false,
            current_time: 0,
            track: {
                id: null,
            },
            playable: {
            },
            volume: 0,
        },

        spotQueue: [],
        currentSpot: null,
        networkTries: 0,
    },
    getters: {
        isCurrentDevice(state) {
            return state.player.session_id === store.state.auth.user.session_id;
        },

        trackName(state) {
            if (!state.player.track) {
                return '';
            }

            if (state.player.track.title) {
                return state.player.track.title;
            }

            if (state.player.track.name) {
                return state.player.track.name.replace(new RegExp("^(.+\/)*", "gm"), '').replace('.mp3', '');
            }

            return '';
        },

        isSpotPlaying(state) {
            return state.currentSpot !== null && state.currentSpot.type === 'spot';
        },

        isSoundPlaying(state) {
            return state.currentSpot !== null && state.currentSpot.type === 'sound';
        },

        canSkip(state, getters) {
            return !getters.isSpotPlaying;
        },
    },
    mutations: {
        updatePlayers(state, payload) {
            state.players = payload;
        },

        updatePlayer(state, payload) {
            state.player = payload;
        },

        updateDevices(state, payload) {
            state.devices = payload;
        },

        addDevice(state, payload) {
            state.devices.push(payload)
        },

        removeDevice(state, payload) {
            state.devices = state.devices.filter((d) => {
                return d.id !== payload.id;
            })
        },

        addSpotsToQueue(state, spots) {
            state.spotQueue.push(...spots);
        },

        shiftSpotFromQueue(state) {
            state.spotQueue.shift();
        },

        emptySpotQueue(state) {
            state.spotQueue = [];
        },

        updateTrack(state, payload) {
            state.player.track = payload;
        },

        updateCurrentSpot(state, payload) {
            state.currentSpot = payload;
        }
    },
    actions: {
        initWebsocketListeners({state}) {
            window.Echo.private('player.' + state.player.id).listen('PlaySpots', (e) => {
                store.commit('player/addSpotsToQueue', e.spots)
            })

            window.Echo.private('player.' + state.player.id).listen('UpdatePlayer', async (e) => {
                console.log('Hello');
                // if (state.player.is_playing) {
                    store.commit('player/updatePlayer', e.player)
                // }
            })

            window.Echo.private('player.' + state.player.id).listen('PlaySound', async (e) => {
                store.commit('player/addSpotsToQueue', [e.sound])
            })
        },

        async switchRandom({state, commit, dispatch}) {
            const res = await axios.post(`/api/players/${state.player.id}/random`);
            commit('updatePlayer', res.data);
        },

        async switchRepeat({state, commit, dispatch}) {
            const res = await axios.post(`/api/players/${state.player.id}/repeat`);
            commit('updatePlayer', res.data);
        },

        async fetchPlayers({commit}) {
            const res = await axios.get('/api/players');
            commit('updatePlayers', res.data);
        },

        // async fetchSounds({commit}) {
        //     const res = await axios.get('/api/sounds');
        //     commit('updateSounds', res.data);
        // },

        // async selectChannel({commit, state, dispatch}, channel) {
        //     const res = await axios.post(`/api/players/${state.player.id}/playable/channel/${channel.id}`)
        //     commit('updatePlayer', res.data);
        //     dispatch('fetchNext');
        //     if (!state.player.is_random) {
        //         dispatch('switchRandom');
        //     }
        // },

        // async selectSound({commit}, sound) {
        //     commit('updatePlayer', sound);
        // },

        // async selectPlaylist({commit, state, dispatch}, playlist) {
        //     const res = await axios.post(`/api/players/${state.player.id}/playable/playlist/${playlist.id}`)
        //     commit('updatePlayer', res.data);
        // },

        // async playTrack({commit, state, dispatch}, payload) {
        //     const res = await axios.post(`/api/players/${state.player.id}/playable/playlist/${payload.playlist.id}`)
        //     const res2 = await axios.get(`/api/players/${state.player.id}/next/tracks/${payload.track.id}`)
        //     commit('updatePlayer', res2.data);
        // },
        //
        // async playChannelTrack({commit, state, dispatch}, payload) {
        //     const res = await axios.post(`/api/players/${state.player.id}/playable/channel/${payload.channel.id}`)
        //     const res2 = await axios.get(`/api/players/${state.player.id}/next/tracks/${payload.track.id}`)
        //     commit('updatePlayer', res2.data);
        // },



        async updateSession({commit, state}, session) {
            const res = await axios.post(`/api/players/${state.player.id}/sessions/${session.id}`, {
                currentTime: state.currentTime,
            })
            commit('updatePlayer',res.data);
        },

        async next({commit, state, getters, dispatch}) {

            if (!getters.canSkip) {
                return;
            }

            try {
                const res = await axios.get(`/api/players/${state.player.id}/next`)
                commit('updatePlayer', res.data);

                if (getters.isSoundPlaying) {
                    dispatch('spotEnded');
                }

                commit('emptySpotQueue');

                return res;
            } catch (e) {
                dispatch('checkNetwork');
            }

        },

        async checkNetwork({dispatch, state}) {
            state.networkTries++;
            try {
                await axios.get('/api/ping');
                state.networkTries = 0;
                console.log('Online at: ' +  new Date())
                dispatch('next');
            } catch (e) {

                if (state.networkTries === 1) {
                    console.log('Offline at: ' +  new Date())
                }

                setTimeout(() => {
                    dispatch('checkNetwork');
                }, (Math.pow(2, state.networkTries)) * 200)

            }
        },

        async trackEnded({dispatch, commit}) {
            await dispatch('next');
        },

        spotEnded({commit}) {
            commit('shiftSpotFromQueue');
            commit('updateCurrentSpot', null);
        },

        spotStarted({commit}, spot) {
            commit('updateCurrentSpot', spot)
        },

        async loadTrack({commit, state}) {
            const res = await axios.get(`/api/players/${state.player.id}/load-track`)
            commit('updateTrack', res.data.track);

            return res;
        },

        async togglePlay({commit, state, dispatch}) {
            const res = await axios.get(`/api/players/${state.player.id}/toggle-play`)
            commit('updatePlayer', res.data);

            return res;
        },

        async updateVolume({commit, state}, payload) {
            const res = await axios.post(`/api/players/${state.player.id}/update-volume`, {
                currentTime: payload.currentTime,
                volume: payload.volume
            });
            commit('updatePlayer', res.data);
        },
    },
};
