import Vue from "vue";
import Vuex from "vuex";
import player from "./modules/player";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        player,
        auth,
    },
});
