<template>
  <div class="">
    <audio ref="trackPlayer" @ended="trackEnded" controls></audio>
    <audio ref="spotPlayer" @ended="spotEnded" controls></audio>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HtmlAudioPlayers",

  props: ['player', 'sessionId'],

  data() {
    return {
      tries: 0,
      networkTries: 0,
    }
  },

  mounted() {

    // window.addEventListener('online', () => {
    //   console.log('online', new Date());
    //
    //   if(this.$refs.trackPlayer.paused && this.$refs.spotPlayer.paused) {
    //     window.location.reload();
    //   }
    // });
    // window.addEventListener('offline',  () => {
    //   console.log('offline', new Date());
    // });

    // this.$refs.trackPlayer.crossOrigin = "anonymous";
    // const context = new (window.AudioContext || window.webkitAudioContext);
    // const source = context.createMediaElementSource(this.$refs.trackPlayer);
    // const gain = context.createGain()
    //
    // source.connect(gain);
    // gain.connect(context.destination);
    // gain.gain.value = Math.exp(0 * (Math.log(10.0) / 20.0));
  },

  computed: {
    isCurrentDevice() {
      return this.player.session_id === this.sessionId;
    },

    shouldPlay() {
      return this.player.is_playing && this.isCurrentDevice;
    },

    isPlaying() {
      return this.$store.state.player.player.is_playing;
    },

    isSpotPlaying() {
      return this.$store.getters['player/isSpotPlaying']
    },

    isSoundPlaying() {
      return this.$store.getters['player/isSoundPlaying']
    },

    trackPlayerSrc() {
      return this.$store.state.player.player.track.url;
    },

    volume() {
      return this.$store.state.player.player.volume;
    },

    spotQueue() {
      return this.$store.state.player.spotQueue;
    }
  },

  methods: {
    trackEnded() {
      this.$store.dispatch('player/trackEnded')
    },

    spotEnded() {
      this.$store.dispatch('player/spotEnded');
    },

    async startTrackPlayer() {
      if (this.shouldPlay) {
        await this.playWithErrorHandling();
      } else {
        this.$refs.trackPlayer.pause();
      }
    },

    async playWithErrorHandling() {
      try {
        await this.$refs.trackPlayer.play();
        this.tries = 0;
      } catch (e) {
        console.log(e.code);
        // code 0 === user didnt interact
        // if (e.code !== 0) {
        //   this.$refs.trackPlayer.pause();
        //   await this.$store.dispatch('player/next');
        //   this.startTrackPlayer();
        // }

        let self = this;

        setTimeout(() => {
          console.log('here');
          self.playWithErrorHandling();
        }, (Math.pow(2, this.tries)) * 200);
        this.tries++;

        if (this.tries === 9) {
          this.$store.dispatch('player/next');
          this.tries = 0;
        }
      }
    },

    startSpotPlayer() {
      if (this.shouldPlay) {
        this.$refs.spotPlayer.play();
      } else {
        this.$refs.spotPlayer.pause();
      }
    }
  },

  watch: {
    isPlaying() {
        if (this.isSpotPlaying) {
          return;
        }

        if (this.isSoundPlaying) {
          this.startSpotPlayer();
          return;
        }

        this.startTrackPlayer();
    },

    trackPlayerSrc() {
      this.$refs.trackPlayer.src = this.trackPlayerSrc;

      if (!this.isSpotPlaying) {
        this.$refs.spotPlayer.pause();
        this.$store.dispatch('player/spotEnded');
        this.startTrackPlayer();
      }
    },

    volume() {
      this.$refs.trackPlayer.volume = this.volume / 100;
      this.$refs.spotPlayer.volume = this.volume / 100;
    },

    isCurrentDevice() {
      this.startTrackPlayer();
    },

    spotQueue() {
      if (!this.shouldPlay) {
        return;
      }

      if (this.isSpotPlaying || this.isSoundPlaying) {
        return;
      }

      if (this.spotQueue.length > 0) {
        this.$refs.trackPlayer.pause();
        this.$refs.spotPlayer.src = this.spotQueue[0].url;
        this.$refs.spotPlayer.play();
        this.$store.dispatch('player/spotStarted', this.spotQueue[0]);
        return;
      }

      this.$refs.trackPlayer.play();
    },

  }
}
</script>

<style scoped>

</style>