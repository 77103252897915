<template>
  <div id="app">
    <Player></Player>
  </div>
</template>

<script>
import Player from './components/Player.vue'

export default {
  name: 'App',
  components: {
    Player
  },

  mounted() {



    // window.addEventListener('online', () => {
    //   console.log('online', new Date());
    //
    //   let player = document.getElementById('player');
    //   let spotPlayer = document.getElementById('spotPlayer');
    //
    //   if(player.paused && spotPlayer.paused) {
    //     window.location.reload();
    //   }
    // });
    // window.addEventListener('offline',  () => {
    //   console.log('offline', new Date());
    // });
  }
}
</script>

<style>
</style>
