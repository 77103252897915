import axios from "axios";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        token: null,
        device: {
            hash_id: null,
        },
    },
    getters: {
        isAuthenticated(state) {
            return state.token !== null;
        }
    },
    mutations: {
        updateToken(state, payload) {
            state.token = payload;
        },
        updateDevice(state, payload) {
            state.device = payload;
        },
    },
    actions: {
        async auth({ commit, state }) {
            const res = await axios.post('api/player-api/auth', {
                code: window.location.pathname.substring(1),
            });
            commit('updateToken', res.data.token)
            const res2 = await axios.get('api/player-api/me');
            commit('updateDevice', res2.data.device);
            commit('player/updatePlayer', res2.data.player, {root: true})

            window.Echo.join(`customer.${state.device.customer_id}`)
                .here((users) => {
                    // store.commit('player/updateDevices', users);
                })
                .joining((user) => {
                    // store.commit('player/addDevice', user)

                })
                .leaving((user) => {
                    // store.commit('player/removeDevice', user)
                })
                .error((error) => {
                    console.error(error);
                });
        }
    },
};
